# QrCode data_acquisition_type
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->

  select_data_acquisition_type      = $('#qr_code_data_acquisition_type')
  select_sgc_sg_group               = $('#select_sgc_sg_group')
  select_service_group              = $('.select_service_group')
  select_notice_kind_group          = $('#select_notice_kind_group')
  select_notice_kind                = $('.select_notice_kind')
  select_ticket_kind_group          = $('#select_ticket_kind_group')
  select_ticket_kind                = $('.select_ticket_kind')
  select_time_tracking_types_group  = $('#select_time_tracking_types_group')
  select_time_tracking_types        = $('.select_time_tracking_types')
  select_operating_data_sheet_group = $('#select_operating_data_sheet_group')
  select_operating_data_sheet       = $('.select_operating_data_sheet')

  select_data_acquisition_type.change ->
    # First disable everything
    select_sgc_sg_group.addClass('d-none')
    select_service_group.attr('name', 'disabled')
    select_notice_kind_group.addClass('d-none')
    select_notice_kind.attr('name', 'disabled')
    select_ticket_kind_group.addClass('d-none')
    select_ticket_kind.attr('name', 'disabled')
    select_time_tracking_types_group.addClass('d-none')
    select_time_tracking_types.attr('name', 'disabled')
    select_operating_data_sheet_group.addClass('d-none')
    select_operating_data_sheet.attr('name', 'disabled')
    # … then enable conditionally
    switch select_data_acquisition_type.val()
      when 'Inspection'
        select_sgc_sg_group.removeClass('d-none')
        select_service_group.attr('name', 'qr_code[service_group_id]')
      when 'Notice'
        select_notice_kind_group.removeClass('d-none')
        select_notice_kind.attr('name', 'qr_code[notice_kind_id]')
      when 'Ticket'
        select_ticket_kind_group.removeClass('d-none')
        select_ticket_kind.attr('name', 'qr_code[ticket_kind_id]')
      when 'TimeTracking'
        select_time_tracking_types_group.removeClass('d-none')
        select_time_tracking_types.attr('name', 'qr_code[time_tracking_type_id]')
      when 'OperatingData'
        select_operating_data_sheet_group.removeClass('d-none')
        select_operating_data_sheet.attr('name', 'qr_code[operating_data_sheet_id]')

  # IMPORTANT
  # Also trigger everything after page (re)load, e.g. validation fail
  select_data_acquisition_type.trigger 'change'


# QrCode location_type
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->

  select_location_type  = $('#qr_code_location_type')
  select_estate_group   = $('#qr_code_form .form-group.select_estate')
  select_estate         = $('#qr_code_form .select_location_estate')
  select_building_group = $('#qr_code_form .form-group.select_building')
  select_building       = $('#qr_code_form .select_location_building')
  select_floor_group    = $('#qr_code_form .form-group.select_floor')
  select_floor          = $('#qr_code_form .select_location_floor')
  input_room_name_group = $('#qr_code_form .form-group.select_room_name')
  input_room_name       = $('#qr_code_room_name')

  select_location_type.change ->
    # First disable everything
    select_estate_group.addClass('d-none')
    select_estate.attr('name', 'disabled')
    select_building_group.addClass('d-none')
    select_building.attr('name', 'disabled')
    select_floor_group.addClass('d-none')
    select_building.attr('name', 'disabled')
    input_room_name_group.addClass('d-none')
    input_room_name.attr('disabled', true)
    # … then enable conditionally
    switch select_location_type.val()
      when 'Estate'
        select_estate_group.removeClass('d-none')
        select_estate.attr('name', 'qr_code[location_id]')
      when 'Building'
        select_estate_group.removeClass('d-none')
        select_building_group.removeClass('d-none')
        select_building.attr('name', 'qr_code[location_id]')
      when 'Floor'
        select_estate_group.removeClass('d-none')
        select_building_group.removeClass('d-none')
        select_floor_group.removeClass('d-none')
        select_floor.attr('name', 'qr_code[location_id]')
        input_room_name_group.removeClass('d-none')
        input_room_name.attr('disabled', false)

  # IMPORTANT
  # Also trigger everything after page (re)load, e.g. validation fail
  select_location_type.trigger 'change'


# QrCode location_id
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->

  select_estate   = $('.select_location_estate')
  select_building = $('.select_location_building')
  select_floor    = $('.select_location_floor')
  buildings       = select_building.html()
  floors          = select_floor.html()

  empty_option = "<option value='' selected='selected'></option>"

  # Disable secondary/tertiary selects of cascades
  select_building.prop('disabled', true) if select_estate.val() == ''
  select_floor.prop('disabled', true) if select_building.val() == ''

  # Populate buildings
  selected_building = select_building.find('option:selected').val()
  select_estate.change ->
    selected_estate = select_estate.find('option:selected').val()
    options = $(buildings).filter("optgroup[label='#{selected_estate}']").html()
    number_of_buildings = if options then (options.match(/value/g) || []).length else 0
    if options
      select_building.prop('disabled', false)
      select_building.html(options)
      # If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Building is selectable
      unless selected_building > 0 || number_of_buildings == 1
        select_building.prepend(empty_option)
      select_floor.prop('disabled', true) if select_building.val() == ''
    else
      select_building.empty()
    populate_select_floors()

  # Populate floors
  select_building.change ->
    select_floor.prop('disabled', false) if select_building.val() != ''
    populate_select_floors()

  populate_select_floors = ->
    building = select_building.find(':selected').val()
    options = $(floors).filter("optgroup[label='#{building}']").html()
    floor = select_floor.find('option:selected').val()
    number_of_floors = if options then (options.match(/value/g) || []).length else 0
    if options
      select_floor.prop('disabled', false)
      select_floor.html(options)
      # If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Floor is selectable
      unless floor > 0 || number_of_floors == 1 || select_floor.find('option:first-child').val() != ''
        select_floor.prepend(empty_option)
    else
      select_floor.empty()

  # IMPORTANT
  # Also trigger everything after page (re)load, e.g. validation fail
  select_estate.trigger 'change'


# QrCode ServiceGroup
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  select_service_group_category = $('.select_service_group_category')
  select_service_group          = $('.select_service_group')
  service_groups                = select_service_group.html()

  empty_option = "<option value='' selected='selected'></option>"

  select_service_group.prop('disabled', true) if select_service_group.val() == ''

  # Populate service groups
  selected_service_group = select_service_group.find('option:selected').val()
  select_service_group_category.change ->
    selected_service_group_category = select_service_group_category.find('option:selected').val()
    options = $(service_groups).filter("optgroup[label='#{selected_service_group_category}']").html()
    number_of_service_groups = if options then (options.match(/value/g) || []).length else 0
    if options
      select_service_group.prop('disabled', false)
      select_service_group.html(options)
      # If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one ServiceGroup is selectable
      unless selected_service_group > 0 || number_of_service_groups == 1
        select_service_group.prepend(empty_option)
      select_service_group.prop('disabled', true) if select_service_group_category.val() == ''
    else
      select_service_group.empty()

  # IMPORTANT
  # Also trigger everything after page (re)load, e.g. validation fail
  select_service_group_category.trigger 'change'
