$(document).on 'turbolinks:load', ->

  select_estate   = $('.select_floor_estate')
  select_building = $('.select_floor_building')
  select_floor    = $('.select_floor_floor')
  buildings       = select_building.html()
  floors          = select_floor.html()

  empty_option = select_estate.find('option:first-child').clone().wrap('<option>').parent().html()

  # Disable secondary/tertiary selects of cascades
  select_building.prop('disabled', true) if select_estate.val() == ''
  select_floor.prop('disabled', true) if select_building.val() == ''

  # Populate buildings
  selected_building = select_building.find('option:selected').val()
  select_estate.change ->
    selected_estate = select_estate.find('option:selected').val()
    options = $(buildings).filter("optgroup[label='#{selected_estate}']").html()
    if options
      select_building.prop('disabled', false)
      select_building.html(options)
      select_floor.prop('disabled', true) if select_building.val() == ''
    else
      select_building.prop('disabled', true)
    select_building.prepend(empty_option) if select_building.find('option:first-child').val() != ''
    select_building[0].selectedIndex = 0 unless selected_building > 0
    populate_select_floors()

  # Populate floors
  select_building.change ->
    select_floor.prop('disabled', false) if select_building.val() != ''
    populate_select_floors()

  selected_floor = select_floor.find('option:selected').val()
  populate_select_floors = ->
    building = select_building.find(':selected').val()
    options = $(floors).filter("optgroup[label='#{building}']").html()
    floor = select_floor.find('option:selected').val()
    if options
      select_floor.prop('disabled', false)
      select_floor.html(options)
    else
      select_floor.prop('disabled', true)
    select_floor.prepend(empty_option) if select_floor.find('option:first-child').val() != ''
    if select_floor[0]
      select_floor[0].selectedIndex = 0 unless selected_floor > 0

  # IMPORTANT
  # Also trigger everything after page (re)load, e.g. validation fail
  select_estate.trigger 'change'
