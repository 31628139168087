$(document).on 'turbolinks:load', ->

  select_report_type   = $('select#reports_schedule_report_type')
  select_time_period   = $('select#reports_schedule_time_period')

  inspection_administered_by_form_group = $('#reports_schedule_administered_by').parent()
  service_group_category_form_group = $('#reports_schedule_service_group_category_id').parent()

  if $('body.reports_schedules-new').length > 0
    # Show option for defect_inspections only when Inspection reports are selected
    select_time_period_options = select_time_period.children()

    select_report_type.change ->
      # Show ServiceGroupCategory select only when Inspection reports are selected
      if select_report_type.val() == 'inspections' || select_report_type.val() == 'defect_inspections'
        inspection_administered_by_form_group.show()
        service_group_category_form_group.show()
        # reset all time_period options
        select_time_period.empty().append(select_time_period_options.clone())
      else
        inspection_administered_by_form_group.hide()
        service_group_category_form_group.hide()
